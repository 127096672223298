import "./Support.css";
import Page from "../../General/Page/Page";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

function Support() {
    return (
        <Page className="support-container">
            <div className="support-body">
                <div className="support-body-text">
                    <div className="support-body-text-container">
                        <h1>Suport Our Project</h1>
                        <p>
                            Betquity Capital is working hard to provide a
                            completely free product to provide you the edge you
                            deserve against the sportsbooks. If you are enjoying
                            our project, please consider donating using the
                            links found below. All of the proceeds go right back
                            into project to help add more functionality and
                            support than ever before.
                        </p>
                        <div className="support-body-buttons">
                            <Link to="https://www.buymeacoffee.com/bmerrick">
                                <Button
                                    className="first-button"
                                    variant="primary"
                                >
                                    <FontAwesomeIcon
                                        icon={faCoffee}
                                        size="lg"
                                    />
                                    Buy Betquity Capital a Coffee
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="support-body-image">
                    <img
                        src="resources/graphics/money.png"
                        alt="Buy Me A Coffee"
                    ></img>
                </div>
            </div>
        </Page>
    );
}

export default Support;
