import "./Team.css";

function Team({ code, name, pick, prediction, spread }) {
    const favoredTeamText = pick ? "team-favorite-text" : "";
    const favoredTeamBold = pick ? "team-favorite-badge" : "";
    const logoURL = "/resources/team-images/" + name + ".png";

    const getTeamSpread = () => {
        let sp = "";
        if (spread === 0) {
            sp = "PK";
        } else if (spread > 0) {
            sp = `+${spread}`;
        } else {
            sp = `${spread}`;
        }
        return sp;
    };

    return (
        <div className="team-container">
            <div className="team-info">
                <img src={logoURL} alt={`${name}-logo`}></img>
                <p className={favoredTeamText}>{name}</p>
            </div>
            <div className={"team-spread " + favoredTeamBold}>
                {getTeamSpread(spread)}
            </div>
        </div>
    );
}

export default Team;
