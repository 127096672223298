import "./GameBody.css";
import Matchup from "./Matchup/Matchup";
import NoGames from "./NoGames/NoGames";
import { onValue, ref } from "firebase/database";
import { getDb } from "../../../../Services/firebase";
import { useEffect, useState } from "react";
import { formatDateToString } from "../../../../Utils/dateUtils";
import Spinner from "react-bootstrap/Spinner";

function GameBody() {
    const [gameData, setGameData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    useEffect(() => {
        const today = formatDateToString(new Date());
        const query = ref(getDb(), "/games/" + today);
        return onValue(query, (snapshot) => {
            setDataFetched(true);
            setGameData(snapshot.val() || []);
        });
    }, []);

    if (!dataFetched) {
        return (
            <div className="spinner-container">
                <Spinner animation="grow" />
            </div>
        );
    } else if (dataFetched && !gameData?.length) {
        return <NoGames />;
    } else {
        return (
            <div className="game-body-container">
                {gameData
                    .filter((matchup) => matchup.home.pick || matchup.away.pick)
                    .map((matchup, index) => {
                        return <Matchup key={index} matchup={matchup} />;
                    })}
            </div>
        );
    }
}

export default GameBody;
