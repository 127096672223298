import { Routes, Route } from "react-router-dom";
import Games from "./Components/Pages/Games/Games";
import NavBar from "./Components/NavBar/NavBar";
import Construction from "./Components/General/Construction/Construction";
import Footer from "./Components/General/Footer/Footer";
import React from "react";
import "./App.css";
import Home from "./Components/Pages/Home/Home";
import About from "./Components/Pages/About/About";
import Support from "./Components/Pages/Support/Support";
import MissingRoute from "./Components/General/MissingRoute/MissingRoute";

function App() {
    return (
        <div className="app-container">
            <NavBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/predictions" element={<Games />} />
                <Route path="/performance" element={<Construction />} />
                <Route path="/support" element={<Support />} />
                <Route path="*" element={<MissingRoute />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
