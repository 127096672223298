import { Card } from "react-bootstrap";
import "./AboutTeamMember.css";

function AboutTeamMember({
    name,
    about,
    role,
    img,
    sportsTeam,
    betOdds,
    memory,
    email,
    linkedin,
}) {
    return (
        <div className="team-member-container">
            <div className="team-member-img">
                <img alt={img} src={"resources/betcap-team/" + img}></img>
            </div>
            <div className="team-member-about">
                <h3>{name}</h3>
                <h4>{role}</h4>
                <p>{about}</p>
                <div className="team-member-about-links">
                    {email !== "" && (
                        <Card.Link href={"mailto:" + email}>Email</Card.Link>
                    )}
                    {linkedin !== "" && (
                        <Card.Link href={linkedin}>LinkedIn</Card.Link>
                    )}
                </div>
            </div>
            <div className="team-member-facts">
                <ul>
                    <li>
                        <div className="team-member-fact">
                            Favorite Sports Team:
                        </div>
                        <p>{sportsTeam}</p>
                    </li>
                    <li>
                        <div className="team-member-fact">Best Odds Hit:</div>
                        <p>{betOdds}</p>
                    </li>
                    <li>
                        <div className="team-member-fact">
                            Favorite Sports Memory:
                        </div>
                        <p>{memory}</p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AboutTeamMember;
