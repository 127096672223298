import "./Matchup.css";
import Team from "./Team/Team";
import Card from "../../../../General/Card/Card";
import { getTZCode } from "../../../../../Utils/dateUtils";
function Matchup({ matchup }) {
    const getHHMMFromDate = (timeStr) => {
        let date = new Date(timeStr);
        return date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };

    const time =
        matchup && matchup.time
            ? getHHMMFromDate(matchup.time) + " " + getTZCode(matchup.time)
            : "00:00pm EST";
    return (
        <Card className="matchup-card">
            <div className="matchup-time">{time}</div>
            <div className="matchup-teams">
                <Team {...matchup.away} />
                <Team {...matchup.home} />
            </div>
        </Card>
    );
}

export default Matchup;
