import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faMailBulk,
    faCoffee,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-body">
                <div className="footer-header">
                    <div className="footer-header-title">
                        <div>Stay connected with us on social networks:</div>
                    </div>
                    <div className="footer-header-links">
                        <a href="https://myaction.app/Yl6fPvRIXxb">
                            <FontAwesomeIcon icon={faCircleCheck} size="lg" />
                        </a>

                        <a href="https://www.buymeacoffee.com/bmerrick">
                            <FontAwesomeIcon icon={faCoffee} size="lg" />
                        </a>
                        <a href="https://www.linkedin.com/in/jonathan-olmanson/">
                            <FontAwesomeIcon icon={faLinkedin} size="lg" />
                        </a>
                        <a href="mailto:b22merrick@gmail.com">
                            <FontAwesomeIcon icon={faMailBulk} size="lg" />
                        </a>
                    </div>
                </div>
                <div className="footer-disclaimer">
                    <p>
                        Disclaimer: All of the information found on this website
                        is for entertainment purposes only. Betquity Capital
                        does not accept bets of any kind nor do we encourage
                        illegal or irresponsible gambling in any form. If you or
                        someone you know has a gambling problem, crisis
                        counseling and referral services can be accessed by
                        calling 1-800-GAMBLER.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Footer;
