import "./NoGames.css";
import Page from "../../../../General/Page/Page";
function NoGames() {
    return (
        <Page>
            <div className="no-games-container">
                <h3>Roadblock!</h3>
                <p>
                    There was either an internal server error or there are no
                    NCAAB games today. Come back later!
                </p>
                <img src="/resources/graphics/404.png" alt="No Games"></img>
            </div>
        </Page>
    );
}

export default NoGames;
