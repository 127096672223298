import "./GameHeader.css";
import { getTodayInWords } from "../../../../Utils/dateUtils";

function GameHeader() {
    const today = getTodayInWords();
    return (
        <div className="game-header-card">
            <div className="game-header-titles">
                <h1 className="game-header-title">
                    Men's College Basketball Predictions
                </h1>
                <h3 className="game-header-date">{today}</h3>
            </div>
            <div className="game-header-text"></div>
        </div>
    );
}

export default GameHeader;
