import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { faBasketball } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./NavBar.css";
function ColorSchemesExample() {
    return (
        <Navbar
            className="navbar-container"
            collapseOnSelect
            expand="md"
            bg="dark"
            variant="dark"
        >
            <Container>
                <Navbar.Brand href="/">
                    <div className="navbar-brand">
                        <FontAwesomeIcon icon={faBasketball} size="2x" />
                        <div>Betquity Capital</div>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                        className="justify-content-end"
                        style={{ width: "100%" }}
                    >
                        <Nav.Link href="/about">
                            <span className="nav-text">About</span>
                        </Nav.Link>
                        <Nav.Link href="/predictions">
                            <span className="nav-text">Predictions</span>
                        </Nav.Link>
                        <Nav.Link href="/performance">
                            <span className="nav-text">Performance</span>
                        </Nav.Link>
                        <Nav.Link href="/support">
                            <span className="nav-text">Support</span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default ColorSchemesExample;
