import GameHeader from "./GameHeader/GameHeader";
import GameBody from "./GameBody/GameBody";
import "./Games.css";
import Page from "../../General/Page/Page";

function Games() {
    return (
        <Page className="game-container">
            <div className="game-body">
                <GameHeader className="game-element" />
                <GameBody className="game-element fade-in-animation" />
            </div>
        </Page>
    );
}

export default Games;
