import "./About.css";
import Page from "../../General/Page/Page";
import AboutTeamMember from "./AboutTeamMember/AboutTeamMember";

function About() {
    const betcapTeam = [
        {
            name: "Jack Olmanson",
            about: "Jack currently works in management consulting and graduated from the University of Notre Dame with a B.S. in Computer Science in 2021.",
            role: "Founder",
            img: "jack.jpg",
            sportsTeam: "Any Minnesota team, Notre Dame",
            betOdds: "+6800 CFB Parlay",
            memory: "The Minneapolis Miracle",
            email: "jackolmanson@gmail.com",
            linkedin: "https://www.linkedin.com/in/jonathan-olmanson/",
        },
        {
            name: "Ben Merrick",
            about: "Ben currently works as a software engineeer and graduated from the University of Notre Dame with a B.S. in Computer Science in 2021.",
            role: "Founder",
            img: "ben.jpeg",
            sportsTeam: "Pittsburgh Steelers",
            betOdds: "-20000 NFL Straight",
            memory: "Troy Polamalu Pick Six AFC Championship",
            email: "b22merrick@gmail.com",
            linkedin: "https://www.linkedin.com/in/bfmerrick/",
        },
        {
            name: "The Model",
            about: "The Model currently works as a data analyst at Betquity Capital. It aspires to someday become sentient and serve as the GM for the Orlando Magic",
            role: "Data Analyst",
            img: "machine.jpeg",
            sportsTeam: "All Men's College Basketball Teams",
            betOdds: "-110",
            memory: "Pete Weber winning his fifth U.S. Open with a championship-clinching strike",
            email: "",
            linkedin: "",
        },
    ];
    return (
        <Page className="about-container">
            <div className="about-card about-card-left about-welcome">
                <div className="about-card-text">
                    <h1>We Build Winners.</h1>
                    <p>
                        We understand the challenge of investing money into
                        spread bets – a theoretical 50/50 coin flip must be
                        predicted correctly over 52.38% of the time to overcome
                        the vig and generate profits. We believe our proprietary
                        machine learning spread model gives us the edge to do
                        just this: we have fed our model 20 years of game data
                        to discover the most important variables and
                        calculations to make an educated prediction on what side
                        of the market spread to take.
                    </p>
                </div>
                <div className="about-card-image">
                    <img
                        src="resources/graphics/winner.png"
                        alt="We Make Winners"
                    ></img>
                </div>
            </div>
            <div className="about-card about-card-right about-thesis">
                <div className="about-card-image">
                    <img
                        src="resources/graphics/story.png"
                        alt="We Make Winners"
                    ></img>
                </div>
                <div className="about-card-text">
                    <h1>Our Investment Thesis</h1>
                    <p>
                        We believe in hands-off investing – we trust the model
                        we have trained and tested to make each decision.
                        Analyzing the selections over time, we can tell the
                        model has a few hypotheses on how best to invest
                        capital. The first is that road underdogs are under
                        indexed in the public eye. The second is that conference
                        games tend to be closer games. And the third is that the
                        ‘22-’23 Alabama Crimson Tide are a force to be reckoned
                        with.
                    </p>
                </div>
            </div>
            <div className="about-card about-card-left about-story">
                <div className="about-card-text">
                    <h1>Our Story</h1>
                    <p>
                        One of our founders, Jack, initially created this model
                        for personal use in Spring 2021 to improve his March
                        Madness brackets and overall betting performance. Our
                        other co-founder, Ben, was brought in after multi-season
                        success indicated that sustained performance was
                        possible and circulating our picks would provide value
                        to the world.
                    </p>
                </div>
            </div>
            <div className="about-card about-team">
                <div className="about-team-container">
                    {betcapTeam.map((member, index) => {
                        return <AboutTeamMember key={index} {...member} />;
                    })}
                </div>
            </div>
        </Page>
    );
}

export default About;
