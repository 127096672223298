import Page from "../Page/Page";
import "./Construction.css";

function Construction() {
    return (
        <Page>
            <div className="construction-card">
                <h1>Under Construction</h1>
                <p>
                    We apologize, but this page is currently under construction.
                    Please come again soon!
                </p>
                <img
                    src="/resources/graphics/construction.png"
                    alt="Under Construction"
                ></img>
            </div>
        </Page>
    );
}

export default Construction;
