import "./Home.css";
import Page from "../../General/Page/Page";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function Home() {
    return (
        <Page className="home-container">
            <div className="home-welcome">
                <div className="home-welcome-text">
                    <div className="home-welcome-text-container">
                        <h1>Welcome to the Sports Betting Revolution</h1>
                        <p>
                            Betquity Capital (est. 2023) strives to be the
                            globe’s leading college basketball sharp and
                            generate superior long-term returns for sports
                            bettors across the world. We do not consider our
                            picks to be gambling, but rather an investment
                            de-risked through tried and tested artificial
                            intelligence modeling.
                        </p>
                        <div className="home-welcome-buttons">
                            <Link to="/about">
                                <Button
                                    className="first-button"
                                    variant="primary"
                                >
                                    Learn More
                                </Button>
                            </Link>
                            <Link to="/predictions">
                                <Button variant="light">See the Picks</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="home-welcome-image">
                    <img
                        src="resources/graphics/court.png"
                        alt="basketball court"
                    ></img>
                </div>
            </div>
        </Page>
    );
}

export default Home;
